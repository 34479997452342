import { useState } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Label, Brush } from 'recharts';
import { Box, Radio, RadioGroup, FormControlLabel, Typography, Checkbox, FormGroup, Grid } from "@mui/material";

const EnergyEfficiencyClassesAreaChart = ({ data, colors }) => {
    const [useLivingSpace, setUseLivingSpace] = useState(false);
    const [useClimateCleaned, setUseClimateCleaned] = useState(false);

    console.log(data);
    
    return (
    
    <Box width="100%" height="40ch">
        <Grid container>
        <Box width="40%">
        <Typography variant="h4" sx={{ display: "flex", justifyContent: "right", marginLeft: "-7%", mt: 1 }}>
            Energieeffizienzklassen
        </Typography>
        </Box>
        <Box width="60%">
        <Typography sx={{ display: "flex", justifyContent: "left", mt: 0.5, ml: 2}}>
            <RadioGroup
            row
            value={useLivingSpace ? "Wohnfläche" : "Gebäude"}
            onChange={(event) => setUseLivingSpace(event.target.value === "Wohnfläche")}
            data-testid="energy-efficiency-chart-radio-group"
            >
                <FormControlLabel
                    value="Gebäude"
                    control={<Radio />}
                    label="Gebäude"
                    data-testid="energy-efficiency-chart-radio-gebäude"
                />
                <FormControlLabel
                    value="Wohnfläche"
                    control={<Radio />}
                    label="Wohnfläche"
                    data-testid="energy-efficiency-chart-radio-wohnfläche"
                />
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox onChange={(event) => setUseClimateCleaned(event.target.checked)} />}
                        label="Witterungsbereinigt"
                        sx={{  ml: 2 }}
                    />
                </FormGroup>
            </RadioGroup>
        </Typography>
        </Box>
        </Grid>
        <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          syncId="eccSyncId"
          width={1200}
          height={250}
          data={useClimateCleaned ? (useLivingSpace ? data.climateCleaned.livingSpace : data.climateCleaned.count) : (useLivingSpace ? data.regular.livingSpace : data.regular.count)}
          margin={{
            top: 10,
            right: 145,
            left: 60,
            bottom: 60,
          }}
          
        >
            <defs>
                <linearGradient id="colorAA" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={colors[0]} stopOpacity={0.8}/>
                <stop offset="90%" stopColor={colors[0]} stopOpacity={0.4}/>
                </linearGradient>
                <linearGradient id="colorA" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={colors[1]} stopOpacity={0.8}/>
                <stop offset="90%" stopColor={colors[1]} stopOpacity={0.4}/>
                </linearGradient>
                <linearGradient id="colorB" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={colors[2]} stopOpacity={0.8}/>
                <stop offset="90%" stopColor={colors[2]} stopOpacity={0.4}/>
                </linearGradient>
                <linearGradient id="colorC" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={colors[3]} stopOpacity={0.8}/>
                <stop offset="90%" stopColor={colors[3]} stopOpacity={0.4}/>
                </linearGradient>
                <linearGradient id="colorD" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={colors[4]} stopOpacity={0.8}/>
                <stop offset="90%" stopColor={colors[4]} stopOpacity={0.4}/>
                </linearGradient>
                <linearGradient id="colorE" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={colors[5]} stopOpacity={0.8}/>
                <stop offset="90%" stopColor={colors[5]} stopOpacity={0.4}/>
                </linearGradient>
                <linearGradient id="colorF" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={colors[6]} stopOpacity={0.8}/>
                <stop offset="90%" stopColor={colors[6]} stopOpacity={0.4}/>
                </linearGradient>
                <linearGradient id="colorG" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={colors[7]} stopOpacity={0.8}/>
                <stop offset="90%" stopColor={colors[7]} stopOpacity={0.4}/>
                </linearGradient>
                <linearGradient id="colorH" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={colors[8]} stopOpacity={0.8}/>
                <stop offset="90%" stopColor={colors[8]} stopOpacity={0.4}/>
                </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year">
                <Label value="Jahr" offset={-4} position="insideBottom" fontSize={16} />
            </XAxis>
            <YAxis>
                <Label value={useLivingSpace ? "Wohnfläche [m²]" : "Anzahl Gebäude"} offset={20} dx={10} dy={-60} position="left" angle={-90} fontSize={16}  />
            </YAxis>
            <Tooltip />
            <Legend formatter={(val, entry) => <span style={{color: "#000"}}>{val}</span>} layout="vertical" verticalAlign="top" align="right" iconSize={24} fontSize={14}
            payload={[
                { value: 'A+', type: 'line', id: 'A+', color: "url(#colorAA)" },
                { value: 'A', type: 'line', id: 'A', color: "url(#colorA)" },
                { value: 'B', type: 'line', id: 'B', color: "url(#colorB)" },
                { value: 'C', type: 'line', id: 'C', color: "url(#colorC)" },
                { value: 'D', type: 'line', id: 'D', color: "url(#colorD)" },
                { value: 'E', type: 'line', id: 'E', color: "url(#colorE)" },
                { value: 'F', type: 'line', id: 'F', color: "url(#colorF)" },
                { value: 'G', type: 'line', id: 'G', color: "url(#colorG)" },
                { value: 'H', type: 'line', id: 'H', color: "url(#colorH)" },
            ]} 
            wrapperStyle={{paddingLeft: '50px', }}
            />
            <Area type="monotone" dataKey="A+" stackId="1" stroke="#999" fill="url(#colorAA)" fillOpacity={1} strokeOpacity={1} dot={{r:2}} unit={useLivingSpace ? "m²": ""}/>
            <Area type="monotone" dataKey="A" stackId="1" stroke="#999" fill="url(#colorA)" fillOpacity={1} strokeOpacity={1} dot={{r:2}} unit={useLivingSpace ? "m²": ""}/>
            <Area type="monotone" dataKey="B" stackId="1" stroke="#999" fill="url(#colorB)" fillOpacity={1} strokeOpacity={1} dot={{r:2}} unit={useLivingSpace ? "m²": ""}/>
            <Area type="monotone" dataKey="C" stackId="1" stroke="#999" fill="url(#colorC)" fillOpacity={1} strokeOpacity={1} dot={{r:2}} unit={useLivingSpace ? "m²": ""}/>
            <Area type="monotone" dataKey="D" stackId="1" stroke="#999" fill="url(#colorD)" fillOpacity={1} strokeOpacity={1} dot={{r:2}} unit={useLivingSpace ? "m²": ""}/>
            <Area type="monotone" dataKey="E" stackId="1" stroke="#999" fill="url(#colorE)" fillOpacity={1} strokeOpacity={1} dot={{r:2}} unit={useLivingSpace ? "m²": ""}/>
            <Area type="monotone" dataKey="F" stackId="1" stroke="#999" fill="url(#colorF)" fillOpacity={1} strokeOpacity={1} dot={{r:2}} unit={useLivingSpace ? "m²": ""}/>
            <Area type="monotone" dataKey="G" stackId="1" stroke="#999" fill="url(#colorG)" fillOpacity={1} strokeOpacity={1} dot={{r:2}} unit={useLivingSpace ? "m²": ""}/>
            <Area type="monotone" dataKey="H" stackId="1" stroke="#999" fill="url(#colorH)" fillOpacity={1} strokeOpacity={1} dot={{r:2}} unit={useLivingSpace ? "m²": ""}/>
            <Brush syncId="eccSyncId" y={250} height={20} />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
    );
};

export default EnergyEfficiencyClassesAreaChart;